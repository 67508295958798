import React, { Component } from 'react';

import { Form, TextInput, Container, Loading, Select } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageMLModel extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = params['id']
            ? `/api/home/mlmodel/${params['id']}/`
            : `/api/home/mlmodel/`;

        this.state = {
            url: url,
            loaded: false,
            data: null,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            this.state.url,
            {},
            function (value) {
                if (!('taxonomy' in value) && params['taxonomy']) {
                    value['taxonomy'] = params['taxonomy'];
                }
                this.setState({ loaded: true, data: value });
            }.bind(this),
        );
    }

    render() {
        let params = get_global_state()['params'];

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={this.state.data}
                        submit_url={this.state.url}
                        redirect={function () {
                            window.location.href = '/admin/home/mlmodel/';
                        }}
                    >
                        <Select
                            name="taxonomy"
                            label="Taxonomy"
                            options_url="/api/home/taxonomy/"
                        />
                        <TextInput name="name" label="Name" />
                    </Form>
                </Loading>
            </Container>
        );
    }
}
