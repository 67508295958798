import React, { Component } from 'react';
import $ from 'jquery';
import { ajax_wrapper } from 'functions';
import { Modal, TextInput, Select, Form, FileInput } from 'library';
import { TagButton } from 'components';
import { sort_objects } from 'functions';

export default class NewMLModal extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
    }

    componentDidUpdate(prevProps) {

        if (this.props.show && !prevProps.show) {

            setTimeout(
                function () {
                    $('.new-tagger-input input').focus();
                }.bind(this),
                100,
            );
        }
    }

    submit(state) {

        let url = `/api/create_ml_model/`;
        let data = {

            name: state.name,
            taxonomy_id: state.target_taxonomy,
            model_type: state.model_type,
            threshold: state.threshold,
            sample_time: state.sample_time,
            start_time: state.start_time,
            mappings_csv: state.mappings_csv,
            state_dict: state.state_dict,
            cnn: state.cnn,
            ignore_warnings: state.ignore_warnings

        };

        ajax_wrapper(
            'POST',
            url,
            data,
            (value) => {
                this.props.on_hide();
                this.props.callback(value);
            }
        );
    }


    render() {

        let model_options = [];
        let threshold_options = [];
        let max_tag_options = [];
        let taxonomy_options = [];


        for (let tagtype of this.props.ml_model_options) {
            model_options.push({'text': tagtype.name, 'value': tagtype.id})
        }

        for (let option of this.props.threshold_options) {
            threshold_options.push({'text': option, 'value': option})
        }

        for (let taxonomy of this.props.taxonomy_options) {
            taxonomy_options.push({'text': taxonomy.name, 'value': taxonomy.id})
        }




        return (
            <div key={new Date().getTime()}>
                <Modal
                    show={this.props.show}
                    on_hide={this.props.on_hide}
                >
                    <div className="simple-card-container modal-bounding-box">
                        <h2>Upload New ML Model</h2>

                        <div className="form-narrow">
                            <Form

                                defaults={{'ignore_warnings': false}}

                                submit={this.submit}
                                submit_text="Create ML Model"
                            >
                                <TextInput
                                    name="name"
                                    label="Name"
                                    required={true}
                                />
                                <br />
                                <Select
                                    name="target_taxonomy"
                                    label="Target Taxonomy:"
                                    options={taxonomy_options}
                                    required={true}
                                />
                                <br />
                                <Select
                                    name="model_type"
                                    label="ML Model Type:"
                                    options={model_options}
                                    required={true}
                                />
                                <br />

                                <h5>Settings:</h5>
                                <table>
                                    <tr>
                                        <td style={{padding: "10px"}}>
                                            <TextInput
                                                name="start_time"
                                                label="Start Time (sec):"
                                                required={true} />
                                        </td>
                                        <td style={{padding: "10px"}}>
                                            <TextInput
                                                name="sample_time"
                                                label="Sample Time (sec):"
                                                required={true} />
                                        </td>
                                        <td style={{padding: "10px"}}>
                                            <Select
                                                name="threshold"
                                                label="Confidence threshold:"
                                                required={true} 
                                                options={threshold_options} />
                                        </td>
                                    </tr>
                                </table>
                                <br />


                                <FileInput 
                                    name="mappings_csv"
                                    label="Class mappings:"
                                    type="file"
                                    required={true}
                                />
                                <br />
                                <FileInput 
                                    name="cnn"
                                    label="Neural Network Architecture:"
                                    type="file"
                                    required={true}
                                />
                                <br />
                                <table>
                                    <tr>
                                        <td>
                                            <FileInput 
                                                name="state_dict"
                                                label="CNN State Dictionary:"
                                                type="file"
                                                required={true}
                                            />
                                        </td>
                                        <td>
                                            <Select
                                                name="ignore_warnings"
                                                label=" "
                                                options={[{'text': 'Ignore Validation', 'value': true}]}
                                                />
                                        </td>
                                    </tr>
                                </table>
                                <br />

                            </Form>
                        </div>

                    </div>

                </Modal>
            </div>
        )

    }

}