import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const getItemStyle = (isDragging, draggableStyle) => ({
    padding: '5px 10px',

    // styles we need to apply on draggables
    ...draggableStyle,
});

export default class SortableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dragging: false,
        };
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            this.setState({ dragging: false });
            return;
        }

        console.log(
            `${result.source.index} moved to ${result.destination.index}`,
        );

        const startIndex = result.source.index;
        const endIndex = result.destination.index;

        const reordered_items = Array.from(this.props.items);
        const [removed] = reordered_items.splice(startIndex, 1);
        reordered_items.splice(endIndex, 0, removed);

        this.props.onDragEnd(result, reordered_items, () =>
            this.setState({ dragging: false }),
        );
    };

    render() {
        if (!this.props.loaded) {
            return null;
        }

        return (
            <DragDropContext
                onDragStart={() => this.setState({ dragging: true })}
                onDragEnd={this.onDragEnd}
            >
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {this.props.items.map((item, index) => (
                                <Draggable
                                    key={item.id}
                                    draggableId={item.id}
                                    index={index}
                                    isDragDisabled={this.state.dragging}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style,
                                            )}
                                        >
                                            {item.content}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }
}
