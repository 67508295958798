import React, { Component } from 'react';

import { ajax_wrapper } from 'functions';
import { SyncCard } from 'components';
import { Container, Loading } from 'library';

export default class ReviewDropboxSyncs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            syncs: [],
        };
    }

    componentDidMount() {
        ajax_wrapper('GET', `/api/review_dropbox_syncs/`, {}, (value) =>
            this.setState({ loaded: true, syncs: value }),
        );
    }

    render() {
        let syncs = [];
        for (let item of this.state.syncs) {
            syncs.push(<SyncCard data={item} />);
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <div>
                            <h2>Dropbox Syncs</h2>
                        </div>
                        <div>
                            <div className="simple-card-container">{syncs}</div>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
