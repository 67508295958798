import React, { Component } from 'react';

import { Form, TextInput, Container, Loading, Select } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

export default class ManageProject extends Component {
    constructor(props) {
        super(props);
        let params = get_global_state()['params'];

        let url = params['id']
            ? `/api/home/project/${params['id']}/`
            : `/api/home/project/`;

        this.state = {
            url: url,
            loaded: false,
            data: null,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper('GET', this.state.url, {}, (value) =>
            this.setState({ loaded: true, data: value }),
        );
    }

    render() {
        let params = get_global_state()['params'];

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <Form
                        defaults={this.state.data}
                        submit_url={this.state.url}
                        redirect={function () {
                            window.location.href = '/admin/home/project/';
                        }}
                    >
                        <TextInput name="name" label="name" />
                        <Select
                            name="client"
                            label="Client"
                            options_url="/api/home/client/"
                        />
                    </Form>
                </Loading>
            </Container>
        );
    }
}
