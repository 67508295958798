import React, { Component } from 'react';

import { Container, Loading, TextInput, Alert, Form } from 'library';
import { ajax_wrapper } from 'functions';

class PasswordResetRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            error: '',
            sent: false,
            loaded: true,
        };
    }

    submit_request = (value, callback) => {
        this.setState({ error: '' }, () =>
            ajax_wrapper(
                'POST',
                `/user/password_reset_request/${value['email']}/`,
                {},
                function (result) {
                    if (result.error) {
                        this.setState({ error: result.error });
                        callback();
                    } else {
                        this.setState({ sent: true });
                        callback();
                    }
                }.bind(this),
            ),
        );
    };

    render() {
        let alert = null;
        if (this.state.error) {
            alert = <Alert type="danger">{this.state.error} </Alert>;
        }

        let sent = null;
        if (this.state.sent == true) {
            sent = (
                <Alert type="success">{`Your password reset email has been sent.`}</Alert>
            );
        }

        const content = (
            <div className="container">
                <h2>
                    Add your email and we'll send you a link to reset your
                    password.
                </h2>

                <Form
                    submit_text="Submit Password Reset"
                    submit={this.submit_request}
                >
                    <TextInput name="email" label="Email" />
                    <br />
                </Form>
                <br />

                {alert}
                {sent}
            </div>
        );

        return (
            <Container className="simple-card-container">
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">{content}</div>
                </Loading>
            </Container>
        );
    }
}
export default PasswordResetRequest;
