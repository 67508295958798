import React, { Component } from 'react';
import { Button } from 'library';

export default class ItemPool extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show_item_select: false,
		};
	}

	render() {
		let items = this.props.items;

		return (
			<div>
				{this.props.label}
				<div className="simple-item-pool">
					<Button
						type="primary"
						className="dark-button"
						style={{ margin: '4px' }}
					>
						+
					</Button>
				</div>
			</div>
		);
	}
}
