import React, { Component } from 'react';
import { Button, Image, Select, TextInput } from 'library';

const KEY_DICT = {
    Low: {
        C: '01 Buzz_C.wav',
        'C#': '02 Buzz_CS or Db.wav',
        D: '03 Buzz_D.wav',
        'D#': '04 Buzz_DS or Eb.wav',
        E: '05 Buzz_E.wav',
        F: '06 Buzz_F.wav',
        'F#': '07 Buzz_FS or Gb.wav',
        G: '08 Buzz_G.wav',
        'G#': '09 Buzz_GS or Ab.wav',
        A: '10 Buzz_A.wav',
        'A#': '11 Buzz_AS or Bb.wav',
        B: '12 Buzz_B.wav',
    },

    High: {
        C: '13 Buzz_C.wav',
        'C#': '14 Buzz_CS or Db.wav',
        D: '15 Buzz_D.wav',
        'D#': '16 Buzz_DS or Eb.wav',
        E: '17 Buzz_E.wav',
        F: '18 Buzz_F.wav',
        'F#': '19 Buzz_FS or Gb.wav',
        G: '20 Buzz_G.wav',
        'G#': '21 Buzz_GS or Ab.wav',
        A: '22 Buzz_A.wav',
        'A#': '23 Buzz_AS or Bb.wav',
        B: '24 Buzz_B.wav',
    },
};

const INSTRUMENTS = ['Buzz', 'Piano', 'Rave', 'Vox'];

const OCTAVES = ['Low', 'High'];

export default class KeyFinder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            octave: 'Low',
            instrument: 'Buzz',
        };
    }

    componentDidMount() {}

    click_event = (e) => {
        console.log(e);
        if (e.type == 'contextmenu') {
            this.right_click_event(e);
            return null;
        }

        var key = e.currentTarget.textContent;

        var filename = KEY_DICT[this.state.octave][key].replace(
            'Buzz',
            this.state.instrument,
        );
        var audio = new Audio('/static/sounds/' + filename);
        audio.play();
    };

    right_click_event = (e) => {
        e.preventDefault();

        console.log(e);

        var key = e.currentTarget.textContent;

        this.props.update({ key: key });
    };

    render() {
        var key_dict = KEY_DICT[this.state.octave];

        var keys = [];
        var whole_notes = 0;
        var sharps = 0;
        for (var key in key_dict) {
            if (key.indexOf('#') > -1) {
                sharps += 1;
            } else {
                whole_notes += 1;
            }
        }

        var width = parseFloat((100 / whole_notes).toFixed(1)) - 0.05;
        var count = 0;
        for (var key in key_dict) {
            var classes = 'key ';
            if (key == this.props.value) {
                classes += 'active ';
            }

            if (key.indexOf('#') > -1) {
                var left = width * count - width / 2;
                var clean_key = key;
                classes += 'sharp ';

                keys.push(
                    <div
                        onClick={this.click_event}
                        onContextMenu={this.click_event}
                        style={{ left: `${left}%`, width: `${width}%` }}
                        className={classes}
                    >
                        <div className="key-text">{clean_key}</div>
                    </div>,
                );
            } else {
                count += 1;
                keys.push(
                    <div
                        onClick={this.click_event}
                        onContextMenu={this.click_event}
                        style={{ width: `${width}%` }}
                        className={classes}
                    >
                        <div className="key-text">{key}</div>
                    </div>,
                );
            }
        }

        var octave_options = [];
        for (var option of OCTAVES) {
            octave_options.push({
                value: option,
                text: option,
            });
        }
        var octave_selector = (
            <div className="col-6">
                <label for="octave">Octave:</label>
                <Select
                    no_blank_option
                    name="octave"
                    value={this.state.octave}
                    set_form_state={(new_state) => this.setState(new_state)}
                    options={octave_options}
                />
            </div>
        );

        var instrument_options = [];
        for (var option of INSTRUMENTS) {
            instrument_options.push({
                value: option,
                text: option,
            });
        }
        var instrument_selector = (
            <div className="col-6">
                <label for="instrument">Instrument:</label>
                <Select
                    no_blank_option
                    name="instrument"
                    value={this.state.instrument}
                    set_form_state={(new_state) => this.setState(new_state)}
                    options={instrument_options}
                />
            </div>
        );

        var keyfinder_selectors = (
            <div className="form-group row" style={{ marginBottom: '20px' }}>
                {octave_selector}
                {instrument_selector}
            </div>
        );

        var major_options = [];
        for (var item of ['Major', 'Minor', 'Neutral']) {
            if (this.props.major_minor == item) {
                major_options.push(
                    <label
                        style={{ color: '#fff' }}
                        className="btn btn-success"
                        for={`major_minor_${item}`}
                    >
                        <input
                            style={{ display: 'none' }}
                            type="radio"
                            className="btn-check major_minor"
                            name="major_minor"
                            id={`major_minor_${item}`}
                            value={item}
                            autocomplete="off"
                            checked
                        />
                        {item}
                    </label>,
                );
            } else {
                major_options.push(
                    <label
                        style={{ color: '#fff' }}
                        className="btn btn-secondary"
                        for={`major_minor_${item}`}
                    >
                        <input
                            style={{ display: 'none' }}
                            type="radio"
                            className="btn-check major_minor"
                            name="major_minor"
                            id={`major_minor_${item}`}
                            value={item}
                            autocomplete="off"
                            onChange={(event) =>
                                this.props.update({
                                    major_minor: event.currentTarget.value,
                                })
                            }
                        />
                        {item}
                    </label>,
                );
            }
        }

        var major_minor_html = (
            <div
                className="form-group row"
                style={{ marginBottom: '20px', marginTop: '40px' }}
            >
                <div className="col-12">
                    <label for="major_minor">Major/Minor:</label>
                    <div>{major_options}</div>
                </div>
            </div>
        );

        var preloads = [];
        for (var octave in KEY_DICT) {
            for (var key in KEY_DICT[octave]) {
                var filename = KEY_DICT[octave][key].replace(
                    'Buzz',
                    this.state.instrument,
                );
                preloads.push(
                    <audio key={filename} controls preload="auto">
                        <source
                            src={`/static/sounds/${filename}`}
                            type="audio/wav"
                        />
                    </audio>,
                );
            }
        }
        var preloads_html = <div style={{ display: 'none' }}>{preloads}</div>;

        var keyboard_html = <div className="keyboard">{keys}</div>;

        return (
            <div key="keyfinder">
                {keyfinder_selectors}
                {keyboard_html}
                {major_minor_html}
                {preloads_html}
            </div>
        );
    }
}
