import React, { Component } from 'react';

import { Loading, Alert, Button } from 'library';
import { UserValidator, ajax_wrapper, get_global_state } from 'functions';

class EntryDisplay extends Component {
    render() {
        let data = this.props.data;

        let content = null;
        if (this.props.type == 'folder') {
            return (
                <tr className="dropbox-entry">
                    <td
                        onClick={() => {
                            this.props.select_folder(data.name);
                        }}
                    >
                        <div className="white-border">
                            <i class="bi bi-folder-fill"></i>
                            <div style={{ marginLeft: '10px' }}>
                                {data.name}
                            </div>
                        </div>
                    </td>
                </tr>
            );
        } else {
            return (
                <tr className="dropbox-entry">
                    <td onClick={() => this.props.toggle_file(data.name)}>
                        <div className="white-border">
                            <i class="bi bi-file-earmark-fill"></i>
                            <div style={{ marginLeft: '10px' }}>
                                {data.name}
                            </div>
                        </div>
                        <div className="checkbox">
                            {this.props.active ? (
                                <i class="white bi bi-check-square-fill"></i>
                            ) : (
                                <i class="white bi bi-square-fill"></i>
                            )}
                        </div>
                    </td>
                </tr>
            );
        }
    }
}

export default class DropboxExplorer extends Component {
    constructor(props) {
        super(props);

        let checker = new UserValidator();

        this.state = {
            loaded: true,
            dropbox_creds: checker.dropbox_creds(),

            dropbox_entries: [],

            selected_folder: [],
            selected_files: [],
        };
    }

    componentDidMount() {
        if (this.state.dropbox_creds) {
            this.search();
        }
    }

    search = () => {
        this.setState(
            {
                loaded: false,
            },
            function () {
                ajax_wrapper(
                    'GET',
                    '/api/dropbox_search/?folder=' +
                        encodeURIComponent(
                            this.state.selected_folder.join('/'),
                        ),
                    {},
                    this.load_search,
                );
            }.bind(this),
        );
    };

    load_search = (value) => {
        if ('error' in value) {
            this.setState({
                loaded: true,
                error: value['error'],
                dropbox_creds: null,
            });
        } else {
            this.setState({ loaded: true, dropbox_entries: value });
        }
    };

    start_dropbox_auth = () => {
        localStorage.setItem('redirect', this.props.redirect_url);
        window.location.href = 'user/dropbox_auth/';
    };

    go_back_folder = () => {
        let selected_folder = this.state.selected_folder.slice(
            0,
            this.state.selected_folder.length - 1,
        );
        this.setState(
            {
                selected_folder: selected_folder,
            },
            this.search,
        );
    };

    toggle_file = (file) => {
        let new_files = this.state.selected_files;

        if (this.state.selected_files.includes(file)) {
            let new_files = this.state.selected_files;
            var index = new_files.indexOf(file);
            if (index !== -1) {
                new_files.splice(index, 1);
            }
        } else {
            new_files.push(file);
        }

        this.setState({ selected_files: new_files }, this.update_form);
    };

    assign_all = () => {
        let all_files = [];
        for (let item of this.state.dropbox_entries) {
            if (item.type !== 'folder') {
                all_files.push(item.name);
            }
        }

        this.setState({ selected_files: all_files }, this.update_form);
    };

    unassign_all = () => {
        this.setState({ selected_files: [] }, this.update_form);
    };

    update_form = () => {
        let new_state = {};
        new_state[this.props.files_name] = this.state.selected_files;
        new_state[this.props.folder_name] = this.state.selected_folder;
        this.props.set_form_state(new_state);
    };

    render() {
        let folders = [];
        let files = [];

        for (let item of this.state.dropbox_entries) {
            if (item.type === 'folder') {
                folders.push(
                    <EntryDisplay
                        active={false}
                        type="folder"
                        data={item}
                        select_folder={(name) => {
                            this.setState(
                                {
                                    selected_folder: [
                                        ...this.state.selected_folder,
                                        name,
                                    ],
                                    selected_files: [],
                                },
                                function () {
                                    this.update_form();
                                    this.search();
                                },
                            );
                        }}
                    />,
                );
            } else {
                let active = '';
                if (this.state.selected_files.includes(item.name)) {
                    active = 'active';
                }

                files.push(
                    <EntryDisplay
                        active={active}
                        type="file"
                        data={item}
                        toggle_file={this.toggle_file}
                    />,
                );
            }
        }

        let dropbox_error = null;
        if (this.state.error) {
            dropbox_error = <Alert type="danger">{this.state.error}</Alert>;
        }

        let dropbox_auth = null;
        if (!this.state.dropbox_creds || this.state.dropbox_creds === '') {
            dropbox_auth = (
                <Button type="primary" onClick={this.start_dropbox_auth}>
                    Sync With Dropbox
                </Button>
            );
        }

        let back_button = null;
        if (this.state.selected_folder.length > 0) {
            back_button = (
                <Button
                    style={{ marginLeft: '10px' }}
                    type="warning"
                    onClick={this.go_back_folder}
                >
                    Go Back
                </Button>
            );
        }

        return (
            <div style={{ position: 'relative' }}>
                <Loading loaded={this.state.loaded} cover={true}>
                    <div className="simple-card">
                        {dropbox_error}

                        {dropbox_auth}

                        <h5>
                            Current Folder:{' '}
                            {this.state.selected_folder.join('/')}
                        </h5>

                        <p>
                            Click through your dropbox folders below until the
                            current folder above shows the folder with the songs
                            you want to import.
                        </p>

                        <div
                            style={{
                                marginBottom: '10px',
                                float: 'right',
                            }}
                        >
                            <Button
                                onClick={this.assign_all}
                                type="primary"
                                style={{ marginLeft: '10px' }}
                            >
                                Assign All
                            </Button>
                            <Button
                                onClick={this.unassign_all}
                                type="danger"
                                style={{ marginLeft: '10px' }}
                            >
                                Unassign All
                            </Button>
                        </div>
                        {back_button}
                    </div>
                    <div className="simple-card">
                        <table className="dropbox-table">
                            <tr>
                                <th>Name</th>
                            </tr>
                            {folders}
                            {files}
                        </table>
                    </div>
                </Loading>
            </div>
        );
    }
}
