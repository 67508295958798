import React from 'react';

class Accordion extends React.Component {
    static component_name = 'Accordion';

    constructor(props) {
        super(props);
    }

    render() {
        const { name } = this.props;
        let clean_name = name
            .toLowerCase()
            .replaceAll(' ', '_')
            .replaceAll("'", '');
        if (this.props.index) {
            clean_name += `_${this.props.index}`;
        }

        let collapsed = 'collapsed';
        let show = '';
        let chevron = <i className="fas fa-chevron-right" />;
        if (this.props.open == true) {
            chevron = <i className="fas fa-chevron-down" />;
            collapsed = '';
            show = 'show';
        }

        const content = (
            <div class="accordion" id={`accordion${clean_name}`}>
                <div class="accordion-item">
                    <button
                        class={`accordion-button ${collapsed}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${clean_name}`}
                        aria-expanded="true"
                        aria-controls={`collapse${clean_name}`}
                    >
                        <h3 class="accordion-header">{name}</h3>
                    </button>
                </div>

                <div
                    id={`collapse${clean_name}`}
                    class={`accordion-collapse collapse ${show}`}
                    data-bs-parent="#accordionExample"
                >
                    <div class="accordion-body">{this.props.children}</div>
                </div>
            </div>
        );

        return (
            <div
                key={clean_name}
                className={`accordion ${this.props.className}`}
                style={this.props.style}
                id="accordion"
            >
                {content}
            </div>
        );
    }
}

export default Accordion;
