import $ from 'jquery';

function handle_error(xhr, status, error) {
    //Error Handler
    console.log('Ajax Failure');
    console.log(xhr.responseText);
    console.log(status);
    console.log(error);
    //Error Handler
}

function clear_tokens(redirect) {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');

    if (redirect) {
        window.location.href = '/login/';
    }
}

function ajax_wrapper(type, url, data, returnFunc, blob_request = false) {
    // Note: blob_request param added explicitly to preserve protections
    //  of current validation.
    // Currently needed for edge cases for file downloads (excel, etc.)

    if (window.location.hostname === 'localhost' && !url.includes(':8000')) {
        url = 'http://localhost:8000' + url;
    }

    if (type === 'POST' || type === 'PUT') {
        data = JSON.stringify(data);
    }

    let authToken = '';
    let beforeSend = null;
    if (localStorage.getItem('token')) {
        authToken = `Bearer ${localStorage.getItem('token')}`;
        beforeSend = (request) =>
            request.setRequestHeader('Authorization', authToken);
    }

    $.ajax({
        type,
        url,
        contentType: 'application/json',
        xhrFields: blob_request ? { responseType: 'blob' } : {},
        beforeSend,
        data,
        statusCode: {
            200(value) {
                console.log('return!');
                console.log(value);
                if (blob_request) {
                    returnFunc(value);
                } else if (
                    value !== null &&
                    typeof value === 'object' &&
                    'redirect' in value
                ) {
                    window.location = `${value.redirect}?redirect=${window.secret_react_state.BASE_URL}`;
                }
                returnFunc(value);
            },
            400(value) {
                value = { error: 'Bad Request' };
                returnFunc(value);
            },
            401(xhr) {
                if (url.endsWith('/user/token/')) {
                    let value = { error: 'Invalid Credentials' };
                    returnFunc(value);
                } else {
                    refresh_token(
                        type,
                        url,
                        data,
                        xhr.responseJSON,
                        returnFunc,
                    );
                }
            },
            408(value) {
                value = { error: 'Request Timed Out' };
                returnFunc(value);
            },
            500(value) {
                alert('Error Detected!');
            },
        },
    });
}

function refresh_token(type, url, data, responseJSON, returnFunc) {
    if (url.includes('/user/user/') && responseJSON.code === 'user_not_found') {
        clear_tokens(true);
        return false;
    }

    let refreshData = {};

    refreshData.refresh = '';
    if (localStorage.getItem('refresh_token')) {
        refreshData.refresh = localStorage.getItem('refresh_token');
    }

    refreshData = JSON.stringify(refreshData);

    // Revert data to JSON for POST and PUT requests
    if (type === 'POST' || type === 'PUT') {
        data = JSON.parse(data);
    }

    let refresh_url = '/user/token/refresh/';
    if (window.location.hostname === 'localhost') {
        refresh_url = 'http://localhost:8000' + refresh_url;
    }

    return $.ajax({
        type: 'POST',
        url: refresh_url,
        contentType: 'application/json',
        data: refreshData,
        statusCode: {
            401(_xhr) {
                clear_tokens(true);
            },
            500(_xhr) {
                clear_tokens(true);
            },
        },
        success(value) {
            localStorage.setItem('token', value.access);
            ajax_wrapper(type, url, data, returnFunc);
        },
        error(xhr, status, error) {
            handle_error(xhr, status, error);
            clear_tokens(true);
        },
    });
}

export default ajax_wrapper;
