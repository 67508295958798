import React, { Component } from 'react';

import { Form, TextInput, Container, FileInput } from 'library';
import { get_global_state, export_taxonomy_template } from 'functions';

export default class ImportTaxonomy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container>
                <h3>New Taxonomy:</h3>
                <a
                    onClick={() => export_taxonomy_template()}
                    style={{ fontStyle: 'italic', textDecoration: 'underline' }}
                >
                    Download Template Here
                </a>
                <Form
                    defaults={this.state.data}
                    submit_url={`/api/import_taxonomy/`}
                    redirect={function () {
                        window.location.href = '/admin/home/taxonomy/';
                    }}
                >
                    <TextInput name="name" label="Name" required={true} />
                    <FileInput name="file" label="File" required={true} />
                </Form>
            </Container>
        );
    }
}
