import React, { Component } from 'react';

export default class DropdownArrow extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		let rotation = this.props.show ? 0 : 180;
		return (
			<img
				class="clear-text-button"
				src="/static/images/dropdown-button-white.png"
				onClick={this.props.onClick}
				style={{
					height: '10px',
					opacity: '80%',
					marginTop: '13px',
					marginLeft: '10px',
					marginRight: '5px',
					verticalAlign: 'middle',
					rotate: rotation + 'deg',
				}}
			/>
		);
	}
}
