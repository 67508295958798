import React, { Component } from 'react';
import $ from 'jquery';
import { Form, TextInput, Container, Loading, Button, Modal } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

import { TaggerCard, NewTaggerModal } from 'components';

import { ObjectList } from './object_list';

function focus_on_page() {
    setTimeout(function () {
        var x = window.scrollX,
            y = window.scrollY;
        $('.admin-page-container').focus();
        window.scrollTo(x, y);
    }, 100);
}

export default class TaggerManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            taggers: [],
            users: [],
            show_new_tagger: false,
            show_new_user: false,
        };
    }

    componentDidMount() {
        this.refresh_data();

        focus_on_page();
    }

    refresh_data = () => {
        ajax_wrapper('GET', `/api/get_taggers_users/`, {}, (value) =>
            this.setState({
                loaded: true,
                taggers: value['taggers'],
                users: value['users'],
            }),
        );
    };

    newTaggerCallback = (value) => this.setState({ taggers: value['taggers'] });

    newUserCallback = (value) => {
        let users = this.state.users;
        users.push(value);

        this.setState({ users: users });
    };

    deleteTagger = (tagger_id) => {
        ajax_wrapper('POST', `/api/delete_tagger/${tagger_id}/`, {}, (value) =>
            this.setState({ taggers: value['taggers'] }),
        );
    };

    render() {
        let tagger_cards = [];

        for (let item of this.state.taggers) {
            tagger_cards.push(
                <TaggerCard
                    data={item}
                    users={this.state.users}
                    deleteSelf={this.deleteTagger}
                />,
            );
        }

        return (
            <div class="admin-page-container">
                <Container>
                    <Loading loaded={this.state.loaded}>
                        <NewTaggerModal
                            data={{ users: this.state.users }}
                            show={this.state.show_new_tagger}
                            callback={this.newTaggerCallback}
                            on_hide={() =>
                                this.setState(
                                    {
                                        show_new_tagger: false,
                                    },
                                    focus_on_page,
                                )
                            }
                        />

                        <Modal
                            show={this.state.show_new_user}
                            on_hide={() =>
                                this.setState({ show_new_user: false })
                            }
                        >
                            <div className="simple-card-container">
                                <div className="simple-card">
                                    <h2>Create New User</h2>
                                    <Form
                                        defaults={{}}
                                        submit_url={'/user/create/'}
                                        submit_text="Create"
                                        redirect={this.newUserCallback}
                                    >
                                        <TextInput
                                            name="email"
                                            label="Email"
                                            required={true}
                                        />
                                        <TextInput
                                            name="password"
                                            label="Initial Password"
                                            required={true}
                                        />
                                        <br />
                                    </Form>
                                </div>
                            </div>
                        </Modal>

                        <div>
                            <div className="simple-card-container">
                                <div className="simple-card">
                                    <div style={{ float: 'right' }}>
                                        <Button
                                            type="primary"
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '10px',
                                            }}
                                            onClick={() =>
                                                this.setState({
                                                    show_new_user: true,
                                                })
                                            }
                                        >
                                            New User
                                        </Button>
                                        <Button
                                            type="primary"
                                            style={{ display: 'inline-block' }}
                                            onClick={() =>
                                                this.setState({
                                                    show_new_tagger: true,
                                                })
                                            }
                                        >
                                            New Tagger
                                        </Button>
                                    </div>
                                    <h1>Active Taggers:</h1>
                                </div>
                                {tagger_cards}
                            </div>
                        </div>
                    </Loading>
                </Container>
            </div>
        );
    }
}
