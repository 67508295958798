import React, { Component } from 'react';
import { Button } from 'library';
import { ajax_wrapper } from 'functions';

export default class TaggerCard extends Component {


    constructor(props) {
        super(props);
        this.state = { 
                        selectedUser: this.props.data.user_id,
                        deleted: '',
                    };
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    assignTaggerToUser = (e) => {

        let user_id = e.target.value;
        let tagger_id = this.props.data['id']


        ajax_wrapper(
            'POST',
            `/api/assign_tagger_to_user/${user_id}/${tagger_id}/`,
            {},
            (value) => value['success'] ? this.setState({ selectedUser: e.target.value }) : '',

        );
    }


    deleteSelf() {

        this.setState({ deleted: 'deleted-card' }, () => {
            let tagger_id = this.props.data['id'];
            this.props.deleteSelf(tagger_id);
        });
    }

    render() {

        const { data, users } = this.props;
        let tagger_projects = []

        for (let proj of this.props.data.current_projects) {
            tagger_projects.push(
                    <Button 
                        className="item-button-outline"
                        style={{margin: '3px'}} 
                        type="primary"
                        href={`/project/${proj.id}`}
                        >
                        {proj.name}
                    </Button>
                )
        }

        return (
            <div className={`simple-card tagger-card ${this.state.deleted}`}>

                <div>
                    <select
                        className="custom-dropdown"
                        defaultValue={this.state.selectedUser}
                        onChange={this.assignTaggerToUser}
                    >
                        <option key='' value='none'></option>
                        {users.map((user) => (
                            <option key={user.id} value={user.id}>
                                {user.email}
                            </option>
                        ))}
                    </select>
                </div>

                <h4>{data['name']}</h4>

                <p style={{ fontStyle: 'italic' }}>
                    {data['email']}
                </p>


                <table className="tagger-table">
                    <tr>
                        <td>

                            <Button className="header-button-display">
                                Current Projects:
                            </Button>
                        </td>
                        <td>

                            {tagger_projects}
                            <br/>
                            
                        </td>
                    </tr>
                    <tr>
                        <td>

                        <Button className="header-button-display">
                            Untagged Tracks:
                        </Button>

                        </td>

                        <td> {data['current_songs']} Tracks </td>
                    </tr>
                    <tr>
                        <td><Button className="header-button-display">
                            Tracks Complete:
                        </Button></td>
                        <td>{data['tracks_complete']}</td>
                    </tr>
                </table>
                <br />

                <Button type="danger" onClick={this.deleteSelf}>
                    Delete Tagger
                </Button>
            </div>
        );
    }
}
