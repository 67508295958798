import { Navigate, Outlet } from 'react-router-dom';
import { useParams, useLocation } from 'react-router';

import {
    UserValidator,
    set_global_state,
    get_search_parameters,
} from 'functions';
import { Header, Footer } from 'components';

export default function AnonRoutes() {
    let params = Object.assign(get_search_parameters(), useParams());
    set_global_state('params', params);
    let location = useLocation();
    set_global_state('location', location);

    let checker = new UserValidator();
    let is_logged_in = checker.logged_id();

    return (
        <div>
            <Header
                is_user={checker.logged_id()}
                is_staff={checker.is_staff()}
            />
            <Outlet />
            <Footer />
        </div>
    );
}
