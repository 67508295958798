import React, { Component } from 'react';

import { Container, Form, Loading, PasswordInput } from 'library';
import { ajax_wrapper, get_global_state } from 'functions';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            found: false,
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/user/password_reset_check/${params['code']}/`,
            {},
            function (result) {
                if (result.found) {
                    this.setState({ loaded: true, found: true });
                } else {
                    this.setState({ loaded: true, found: false });
                }
            }.bind(this),
        );
    }

    render() {
        let params = get_global_state()['params'];
        const submit_url = `/user/password_reset/${params['code']}/`;

        const { found: foundState } = this.state;
        let content = (
            <div>
                <h2>Your Password Reset Link Has Expired.</h2>
                <a href="/password_reset_request/">
                    Please Re-initiate a password reset request by clicking here
                </a>
            </div>
        );
        if (foundState) {
            content = (
                <div className="container">
                    <h2>Reset Password</h2>
                    <Form
                        redirect={() => {
                            window.location = '/login/';
                        }}
                        submit_url={submit_url}
                        defaults={{ password: '' }}
                    >
                        <PasswordInput confirm_password={true} />
                        <br />
                    </Form>
                </div>
            );
        }

        return (
            <Container className="simple-card-container">
                <Loading loaded={this.state.loaded}>
                    <div className="simple-card">{content}</div>
                </Loading>
            </Container>
        );
    }
}

export default PasswordReset;
