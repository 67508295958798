import React, { Component } from 'react';
import { TagButton } from 'components';

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            dropdown_button_rotation: 0,
        };
    }

    show_hide = () => {
        console.log('hide');
        if (this.state.show) {
            this.setState({ show: false, dropdown_button_rotation: 0 });
        } else {
            this.setState({ show: true, dropdown_button_rotation: 180 });
        }
    };

    render() {
        let parent = this.props.parent;
        let parent_jsx = '';
        let dropdown_arrow = (
            <img
                class="clear-text-button"
                src="/static/images/dropdown-button-white.png"
                style={{
                    height: '8px',
                    opacity: '80%',
                    float: 'right',
                    marginTop: '13px',
                    marginLeft: '10px',
                    marginRight: '5px',
                    rotate: this.state.dropdown_button_rotation + 'deg',
                }}
            />
        );

        if (parent) {
            if (parent['selectable']) {
                let parent_active = this.props.song_tags.includes(parent['id'])
                    ? 'selected'
                    : '';
                parent_jsx = (
                    <div id="dropdown-click-zone">
                        <div
                            className={`tag-dropdown-header parent-selectable ${parent_active}`}
                            onClick={(e) => {
                                e.stopPropagation();
                                this.props.toggle_tag(parent['id']);
                            }}
                        >
                            {parent.name}
                        </div>
                        {dropdown_arrow}
                    </div>
                );
            } else {
                parent_jsx = (
                    <div className="tag-dropdown-header">
                        {parent.name}
                        {dropdown_arrow}
                    </div>
                );
            }
        }

        let children_jsx = [];
        for (let tag of this.props.tags) {
            children_jsx.push(
                <TagButton
                    data={tag}
                    active={
                        this.props.song_tags.includes(tag['id']) ? 'active' : ''
                    }
                    toggle_tag={this.props.toggle_tag}
                />,
            );
        }

        return (
            <div class="tag-dropdown-container" onClick={this.show_hide}>
                {parent_jsx}
                <div style={{ display: this.state.show ? 'block' : 'none' }}>
                    {children_jsx}
                </div>
            </div>
        );
    }
}

export default class TagDropdowns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdowns: [],
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setupTags();
        }
    }

    componentDidMount() {
        this.setupTags();
    }

    setupTags = () => {
        let categories = {};
        let parent_tags = {};
        let parents_children = {};
        let child_tags = [];
        let dropdowns = [];

        for (let tag of this.props.tags) {
            let category = tag.dropdown_category;

            if (tag.parent === null) {
                parent_tags[tag.id] = tag;
            } else {
                if (!(tag.parent in parents_children)) {
                    parents_children[tag.parent] = [];
                }

                parents_children[tag.parent].push(tag);
            }
        }

        // If only parent tags, i.e. no children, render list of buttons
        //  (organized by category)
        if (Object.keys(parents_children).length === 0) {
            for (let tag of this.props.tags) {
                let category = tag.dropdown_category
                    ? this.props.dropdown_categories[tag.dropdown_category]
                    : 'NONE';

                if (!(category in categories)) {
                    categories[category] = [];
                }

                categories[category].push(
                    <TagButton
                        data={tag}
                        active={
                            this.props.song_tags.includes(tag['id'])
                                ? 'active'
                                : ''
                        }
                        toggle_tag={this.props.toggle_tag}
                    />,
                );
            }
            // Else render full list of dropdowns
        } else {
            for (let parent_id of Object.keys(parents_children)) {
                // grab category name from first child
                let first_child = parents_children[parent_id][0];
                let category = first_child.dropdown_category
                    ? this.props.dropdown_categories[
                          first_child.dropdown_category
                      ]
                    : 'NONE';

                if (!(category in categories)) {
                    categories[category] = [];
                }
                categories[category].push(
                    <Dropdown
                        parent={parent_tags[parent_id]}
                        tags={
                            parents_children[parent_id]
                                ? parents_children[parent_id]
                                : []
                        }
                        song_tags={this.props.song_tags}
                        toggle_tag={this.props.toggle_tag}
                    />,
                );
            }
        }

        if (Object.keys(categories).length === 1) {
            dropdowns = categories[Object.keys(categories)[0]];
        } else {
            dropdowns.push(categories['NONE']);
            dropdowns.push(<hr />);

            for (let category of Object.keys(categories)) {
                if (category !== 'NONE') {
                    dropdowns.push(
                        <h3
                            style={{
                                fontStyle: 'italic',
                                fontWeight: 'lighter',
                            }}
                        >
                            {category}
                        </h3>,
                    );
                    dropdowns.push(categories[category]);
                    dropdowns.push(<hr />);
                }
            }
        }
        this.setState({ dropdowns: dropdowns });
    };

    render() {
        return <div>{this.state.dropdowns}</div>;
    }
}
