import React, { Component } from 'react';
import { Button } from 'library';
import { ajax_wrapper } from 'functions';

export default class MLModelCard extends Component {


    constructor(props) {
        super(props);
        this.state = { 
                        deleted: '',
                    };
        this.deleteSelf = this.deleteSelf.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.id !== this.props.data.id) {
            this.setState({ deleted: '' });
        }
    }


    deleteSelf() {

        this.setState({ deleted: 'deleted-card' }, () => {
            let tagger_id = this.props.data['id'];
            this.props.deleteSelf(tagger_id);
        });
    }

    render() {

        console.log(this.props.data)

        return (
            <div className={`simple-card tagger-card ${this.state.deleted}`}>
                <h4>{this.props.data.name}</h4>
                <p>{this.props.data.taxonomy}</p>
                <span>
                <a href={this.props.data.cnn_filename}>CNN Architecture</a><p> (.py)</p>
                </span>
                <span>
                <a href={this.props.data.state_dict}>State Dictionary</a><p> (.pth)</p>
                </span>
                <span>
                <a href={this.props.data.output_mapping_filepath}>Output Mapping</a><p> (.csv)</p>
                </span>
                <br />
                <Button type="danger" onClick={this.deleteSelf}>
                    Delete Model
                </Button>
            </div>
        );
    }
}
