import React, { Component } from 'react';

import { Button } from 'library';

export default class SyncCard extends Component {
    render() {
        let data = this.props.data;
        let project_data = data;

        console.log(data);
        return (
            <div className="simple-card">
                <div style={{ float: 'right' }}>
                    <Button
                        type="primary"
                        href={`/dropbox_sync/${data['id']}/`}
                    >
                        View
                    </Button>
                </div>
                <h4>{project_data['dropbox_folder']}</h4>
                <p style={{ fontStyle: 'italic' }}>
                    {project_data['project_name']}
                </p>
                <div>
                    <span>{`Status: ${project_data['status']}`}</span>
                    <span
                        style={{ display: 'inline-block', padding: '10px' }}
                    >{`|`}</span>
                    <span>{`Songs: ${project_data['completed_songs']} / ${project_data['total_songs']}`}</span>
                </div>
            </div>
        );
    }
}
