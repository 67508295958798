import React, { Component } from 'react';

import { ajax_wrapper, get_global_state } from 'functions';
import { Container, Loading } from 'library';

export default class DropboxSync extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            sync: null,
            songs: [],
        };
    }

    componentDidMount() {
        let params = get_global_state()['params'];

        ajax_wrapper(
            'GET',
            `/api/home/dropboxsync/${params['id']}/`,
            {},
            (value) => this.setState({ loaded: true, sync: value }),
        );

        ajax_wrapper(
            'GET',
            `/api/home/songsync/?sync=${params['id']}&only_fields=name,status`,
            {},
            (value) => this.setState({ songs: value }),
        );
    }

    render() {
        let songs = [];
        for (let item of this.state.songs) {
            songs.push(
                <div className="simple-card">
                    <span
                        style={{ fontStyle: 'italics', float: 'right' }}
                    >{`${item['status']}`}</span>
                    <span>{`${item['name']}`}</span>
                </div>,
            );
        }

        return (
            <Container>
                <Loading loaded={this.state.loaded}>
                    <div>
                        <div>
                            <h2>Dropbox Sync</h2>
                        </div>

                        <div>
                            <div className="simple-card-container">
                                <div className="simple-card">
                                    <h4>
                                        {`Folder: ${
                                            this.state.sync
                                                ? this.state.sync[
                                                      'dropbox_folder'
                                                  ]
                                                : null
                                        }`}
                                    </h4>
                                </div>

                                {songs}
                            </div>
                        </div>
                    </div>
                </Loading>
            </Container>
        );
    }
}
