import React, { Component } from 'react';

const DESIRED_TAGS = ['Genres', 'Subgenres', 'Styles', 'Moods'];

export default class SongListItem extends Component {
    render() {
        let data = this.props.data;

        let tag_types_lookup = {};
        for (let tag_type of this.props.tag_types) {
            tag_types_lookup[tag_type['id']] = tag_type;
        }

        let tags = [];
        for (let type of DESIRED_TAGS) {
            for (let tag of data.tags) {
                if (
                    tag.tag_type in tag_types_lookup &&
                    tag_types_lookup[tag.tag_type]['name'] == type
                ) {
                    tags.push(
                        <div
                            className="badge text-bg-info"
                            style={{ marginRight: '5px' }}
                        >
                            {tag.name}
                        </div>,
                    );

                    break;
                }
            }
        }

        let tag_text = tags.join(',');

        let style = {};
        if (this.props.onClick) {
            style = { cursor: 'pointer' };
        }

        let active = '';
        if (this.props.parent_song == data['id']) {
            active = 'alert alert-info';
        }

        return (
            <div
                className={`song-list-item simple-card ${active}`}
                style={style}
                onClick={this.props.onClick}
            >
                {this.props.children}
                {tags}
                <div className="song-title">{data['name']}</div>
            </div>
        );
    }
}
