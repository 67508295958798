import React, { Component } from 'react';

import { Form, Container, FileInput } from 'library';

export default class UploadPresets extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container>
                <h3>Upload Presets</h3>
                <Form
                    defaults={this.state.data}
                    submit_text="Upload"
                    submit_url={`/api/import_presets/${this.props['taxonomy_id']}/`}
                    redirect={() => {
                        window.location.href = `/admin/taxonomy/${this.props['taxonomy_id']}/`;
                    }}
                >
                    <FileInput name="file" label="Excel File" required={true} />
                    <br />
                </Form>
            </Container>
        );
    }
}
