import { ajax_wrapper, get_global_state } from 'functions';

export function export_project(project_id, format_id) {
    ajax_wrapper(
        'GET',
        `/api/project_export/${project_id}/${format_id}/`,
        {},
        (value) => downloadCSVFile(value),
    );
}

export function export_taxonomy(taxonomy_id, taxonomy_name) {
    ajax_wrapper('GET', `/api/export_taxonomy/${taxonomy_id}/`, {}, (value) =>
        downloadExcelFile(value, taxonomy_name),
    );
}

export function export_taxonomy_template() {
    ajax_wrapper(
        'GET',
        `/api/export_taxonomy/`,
        {},
        (value) => downloadExcelFile(value, 'Taxonomy Template.xlsx'),
        true,
    );
}

function downloadCSVFile(value) {
    const element = document.createElement('a');
    const file = new Blob([value['text']], { type: 'text/csv' });
    element.href = URL.createObjectURL(file);
    element.download = value['filename'];
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

function downloadExcelFile(fileResponse, filename) {
    const element = document.createElement('a');
    const file = new Blob([fileResponse], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    element.remove();
    URL.revokeObjectURL(element.href);
}
